// src/components/PhotoDetails/PhotoDetail.jsx

import React, { useEffect, useRef, useMemo } from 'react';
import { useAssetStore } from '../../store_asset_detail';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import '../../styles/AssetDetail.css';
import DownloadPhotoButton from './../DownloadPhotoButton';
import SimilarPhotos from './SimilarPhotos';

const PhotoDetail = () => {
  const { slug } = useParams();
  const { asset, loading, error, fetchAssetBySlug, clearAsset } = useAssetStore();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (slug) {
      fetchAssetBySlug(slug);
    }
    return () => {
      clearAsset();
    };
  }, [slug, fetchAssetBySlug, clearAsset]);

  /**
   * Важно: хуки должны идти *до* любых return!
   * Даже если asset не загружен, мы всё равно вызываем хук,
   * просто внутри проверки сделаем fallback.
   */
  const keywords = useMemo(() => {
    if (!asset || !asset.keywords) return 'No keywords provided';
    return Array.isArray(asset.keywords)
      ? asset.keywords.join(', ')
      : asset.keywords;
  }, [asset]);

  const width = parseInt(asset?.url_small_width, 10) || 0;
  const height = parseInt(asset?.url_small_height, 10) || 0;
  const aspectRatio = width && height ? width / height : 16 / 9;

  // Проверка состояния после того, как хуки объявлены:
  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  if (!asset) {
    return <div className="asset-not-found">Asset not found</div>;
  }

  // Ниже — основной JSX, который будет отрисован, когда есть asset:
  const freeOrPaid = asset.is_free ? 'This image is free' : 'This image is premium';
  const description = asset.description || 'Beautiful photo for commercial use.';

  const getLicenseUrl = () => 'https://awesomeheap.com/terms';
  const getCanonicalUrl = () => `https://awesomeheap.com/photo/${slug}`;

  // JSON-LD
  const getImageObjectJSON = () => {
    return {
      '@context': 'https://schema.org/',
      '@type': 'ImageObject',
      contentUrl: asset.url_medium,
      license: getLicenseUrl(),
      acquireLicensePage: getLicenseUrl(),
      creditText: asset.title || 'AwesomeHeap photo',
      creator: {
        '@type': 'Person',
        name: 'Davooda',
      },
      copyrightNotice: 'Davooda',
    };
  };

  const getBreadcrumbsJSON = () => {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Home',
          item: 'https://awesomeheap.com/',
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'Photos',
          item: 'https://awesomeheap.com/photo',
        },
        {
          '@type': 'ListItem',
          position: 3,
          name: asset.title || 'Photo Detail',
          item: getCanonicalUrl(),
        },
      ],
    };
  };

  return (
    <div className="image-detail-container">
      <Helmet>
        <title>{asset.title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        
        {/* Open Graph */}
        <meta property="og:title" content={asset.title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={asset.url_medium} />
        <meta property="og:type" content="website" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={asset.title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={asset.url_medium} />

        {/* Canonical */}
        <link rel="canonical" href={getCanonicalUrl()} />

        {/* JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify(getImageObjectJSON())}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(getBreadcrumbsJSON())}
        </script>
      </Helmet>

      <div className="image-detail-content">
        <div className="image-container">
          <img
            src={asset.url_medium}
            alt={asset.title || 'Image'}
            className="asset-image"
            loading="lazy"
            style={{ aspectRatio: `${aspectRatio}` }}
          />
        </div>
        <div className="image-info">
          <h1 className="small-h1-title">{asset.title}</h1>
          <p className="keywords">
            <strong>Keywords:</strong> {keywords}
          </p>
          <p>
            <strong>License:</strong>{' '}
            <a href="https://awesomeheap.com/terms">
              {freeOrPaid}, view license terms
            </a>
          </p>
          <DownloadPhotoButton />
        </div>
      </div>
      <SimilarPhotos slug={slug} />
    </div>
  );
};

export default PhotoDetail;
