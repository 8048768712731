import React, { useState, useEffect, useRef } from 'react';
import useAdditionalStorage from '../additional_storage';

const PopupMakeALink = () => {
  const { isOpen, togglePopup } = useAdditionalStorage();
  const [copied, setCopied] = useState(false);
  const linkToCopy = '<a href="https://awesomeheap.com">Image by awesomeheap.com</a>';
  const panelRef = useRef(null);

  const handleCopyURL = () => {
    navigator.clipboard.writeText(linkToCopy).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    });
  };

  const handleOverlayClick = () => {
    togglePopup();
  };

  const handlePanelClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => {
        if (panelRef.current) {
          panelRef.current.style.transform = 'translateX(0)';
        }
      });
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      onClick={handleOverlayClick}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        zIndex: 9999,
        background: 'rgba(0,0,0,0.5)',
        fontFamily: "'Poppins', sans-serif",
        transition: 'opacity 0.3s ease',
        overflow: 'hidden',
        cursor: 'pointer'
      }}
    >
      <div
        ref={panelRef}
        onClick={handlePanelClick}
        style={{
          background: '#fff',
          width: '600px',
          maxWidth: '90%',
          height: '100%',
          boxShadow: '2px 0 10px rgba(0,0,0,0.1)',
          display: 'flex',
          flexDirection: 'column',
          padding: '80px',
          boxSizing: 'border-box',
          transform: 'translateX(-100%)',
          transition: 'transform 0.3s ease',
          cursor: 'auto',
          position: 'relative',
          fontFamily: "'Poppins', sans-serif"
        }}
      >
        <img
          src="/images/close_cross_big.svg"
          alt="Close"
          style={{
            position: 'absolute',
            top: '30px',
            right: '30px',
            cursor: 'pointer',
            width: '20px',
            height: '20px'
          }}
          onClick={togglePopup}
        />

        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          transform: 'translateY(-100px)'
        }}>
          <h2 style={{
            margin: 0,
            marginBottom: '20px',
            fontWeight: '700',
            fontSize: '24px',
            color: '#111'
          }}>Please, attribute this image</h2>
          <p style={{
            fontSize: '16px',
            color: '#333',
            marginBottom: '20px',
            lineHeight: '1.4'
          }}>
            We have provided you with high-quality creative content at no charge.
            If you choose to use it, we kindly ask that you include a credit link back to our website, as outlined in our usage guidelines.
          </p>
          
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              padding: '14px 24px',
              border: '1px solid #333',
              borderRadius: '8px',
              cursor: 'pointer',
              transition: 'background 0.3s ease, color 0.3s ease, border 0.3s ease',
              minWidth: '220px',
              gap: '12px',
              ...(copied ? { background: 'green', color: '#fff', border: 'none' } : {})
            }}
            onClick={handleCopyURL}
          >
            {copied ? (
              'Copied!'
            ) : (
              <>
                <span style={{ fontSize: '16px', whiteSpace: 'nowrap' }}>
                  Image by awesomeheap.com
                </span>
                <img
                  src="/images/copy.svg"
                  alt="Copy"
                  style={{ width: '20px', height: '20px' }}
                />
              </>
            )}
          </div>

          <button
            onClick={togglePopup}
            style={{
              marginTop: '40px',
              display: 'inline-block',
              background: '#ddd',
              border: 'none',
              borderRadius: '8px',
              padding: '10px 20px',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: "'Poppins', sans-serif"
            }}
          >
            I will add the link
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupMakeALink;
